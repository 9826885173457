<script>
import { cartKey } from "@/keys";
import * as sales from "@/commands/sales";
import { fetchPageBuilder } from "@drapejs/core";
import { useGA4 } from '@distancify-storefront/tracking-gtm';

export default {
  data: () => {
    return {
      klarnaSnippet: "",
      order: null,
    };
  },
  inject: {
    cart: cartKey,
  },
  // computed: {
  //   //twoLetterISOLanguageName: state => state.cart.twoLetterISOLanguageName,
  // },
  watch: {
    klarnaSnippet(payload) {
      if (!payload) return;

      this.$nextTick(() => {
        var scriptsTags = this.$refs.klarnaArea.getElementsByTagName('script');

        for (var i = 0; i < scriptsTags.length; i++) {
          var parentNode = scriptsTags[i].parentNode;
          var newScriptTag = document.createElement('script');
          newScriptTag.type = 'text/javascript';
          newScriptTag.text = scriptsTags[i].text;
          parentNode.removeChild(scriptsTags[i]);
          parentNode.appendChild(newScriptTag);
        }
      });
    }
  },
  methods: {
    async getKlarnaOrder() {
      const request = this.buildRequest();
      const klarnaResponse = await this.$invoke(
        sales.commands.getKlarnaOrder,
        request
      );

      return klarnaResponse;
    },
    async clearCart() {
      await this.$invoke(sales.commands.clearCart, { url: this.$route.href });
    },
    buildRequest() {
      return {
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          {
            ...this.$route.query,
          },
          ""
        ),
        accountId: this.$route.query.accountId,
        transactionNumber: this.$route.query.transactionNumber,
      };
    },
    triggerGoogleCustomerReviews() {
      // var productsGTIN = trackingData.transactionProducts.map(function (i) {
      //     return { "gtin": i.gtin };
      // });

      // var deliveryDate = new Date();
      // deliveryDate = this.addDays(deliveryDate, 3);

      // var year = deliveryDate.getFullYear();

      // var month = deliveryDate.getMonth() + 1;
      // month = month < 10 ? '0' + month : month;

      // var day = deliveryDate.getDate();
      // day = day < 10 ? '0' + day : day;

      // var deliveryDateString = year + "-" + month + "-" + day;

      // window.renderOptIn = function () {

      //     window.gapi.load('surveyoptin', function () {
      //         window.gapi.surveyoptin.render({
      //             "merchant_id": 122849684,
      //             "order_id": trackingData.transactionId,
      //             "email": trackingData.transactionEmail,
      //             "delivery_country": trackingData.transactionShippingCountryCode,
      //             "estimated_delivery_date": deliveryDateString,

      //             "products": productsGTIN
      //         });
      //     });
      // }

      // window.___gcfg = {
      //     lang: this.twoLetterISOLanguageName
      // };
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }
  },
  async mounted() {
    const klarnaResponse = await this.getKlarnaOrder();
    this.order = klarnaResponse?.receipt;
    this.klarnaSnippet = klarnaResponse?.htmlSnippet;

    if (this.order.consent) {
      window.addEventListener('CookiebotOnConsentReady', function (e) {
        try {
          Cookiebot.submitCustomConsent(Cookiebot.consent.preferences, true, true);
        } catch (e) {
          console.error('Cookiebot error', e);
        }
      }, false);
    }

    const ga4 = useGA4({
      useLegacyEnhancedECommerce: true,
    });

    ga4.purchase({
      currency: this.order.currency?.id,
      transactionId: this.order?.cartOrderId || '',
      items: this.order.rows.map((row) => {
        return {
          item_id: row.modelNumber,
          item_name: row.variantName,
          item_color: row.color,
          item_color_id: row.baseProductId,
          item_variant: row.size,
          price: row.unitListPrice,
          discount: row.unitListPrice - row.unitPrice,
          quantity: row.quantity,
          coupon: row.campaignName,
        };
      }),
      tax: this.order.totalVat,
      shipping: this.order.totalDeliveryCost,
      coupon: this.order.discountCode,
      customVariables: {
        customerEmail: this.order.customerEmail,
        customerNumber: this.order.customerNumber,
        fb_contents: this.order.rows.map((row) => {
          return {
            id: row.articleNumber,
            quantity: row.quantity
          };
        })
      },
    });

    await this.clearCart();
    //this.triggerGoogleCustomerReviews();
  }
}
</script>

<template>
  <div class="klarna-receipt">
    <div v-if="klarnaSnippet" ref="klarnaArea" v-html="klarnaSnippet"></div>
  </div>
</template>

<style>
.klarna-receipt {
  margin: auto;
}
</style>